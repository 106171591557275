var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pa-8' : _vm.$vuetify.breakpoint.smOnly ? 'pa-4' : 'pa-0'},[_c('v-card',{attrs:{"tile":""}},[_c('h1',{staticClass:"display-1 primary--text headline py-4 pl-4"},[(_vm.$store.getters.getFranchiseName == null)?[(_vm.$store.getters.companyInfo.name == null)?[_vm._v(" "+_vm._s(((_vm.$t("base.welcome_to")) + " " + _vm.title))+" ")]:[_vm._v(" "+_vm._s(((_vm.$t("base.welcome_to")) + " " + (_vm.$store.getters.companyInfo.name)))+" ")]]:[_vm._v(" "+_vm._s(((_vm.$t("base.welcome_to")) + " " + (_vm.$store.getters.getFranchiseName) + " - " + (_vm.$store.getters.getFranchiseBranchOffice)))+" ("+_vm._s(("" + (_vm.$tc("franchise.contractor",2))))),_c('span',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(_vm.$t("of")))]),_vm._v(" "+_vm._s(_vm.$store.getters.companyInfo.name)+") ")]],2),_c('CardParent',{attrs:{"classParent":'cards-totals'}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticStyle:{"padding-top":"0 !important"},attrs:{"fluid":""}},[_c('v-row',[(_vm.$store.getters.isRoot)?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":_vm.setup.companies.expand ? 6 : 12,"order":_vm.setup.companies.expand ? 'first' : _vm.companies != 0 ? '' : 'last'}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","chart":""}},[_c('v-list-item',{staticClass:"primary elevation-4",attrs:{"header-chart":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"title mt-4 mb-0 text-uppercase white--text"},[_vm._v(" "+_vm._s(("" + (_vm.$tc('company.company',1))))+" ")]),_c('div',{staticClass:"overline mb-5 white--text ml-auto"},[_vm._v(" "+_vm._s(((_vm.$tc('franchise.total',2)) + " " + (this.companies + this.companiesDeactives)))+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.companies.minimize = !_vm.setup.companies.minimize}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-window-minimize")])],1)]}}],null,false,591316838)},[_c('span',[_vm._v(_vm._s(_vm.$t('minimize')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.getCompanies()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-redo")])],1)]}}],null,false,3983378915)},[_c('span',[_vm._v(_vm._s(_vm.$t('update')))])])],1),(!_vm.setup.companies.minimize && _vm.companies != 0 || _vm.companiesDeactives != 0)?_c('v-card-actions',{attrs:{"content-chart":""}},[_c('fusioncharts',{ref:"fc",attrs:{"type":_vm.chartRootCompany.type,"width":_vm.chartRootCompany.width,"height":_vm.chartRootCompany.height,"dataFormat":_vm.chartRootCompany.dataFormat,"dataSource":_vm.dataSourceCompany}})],1):_vm._e(),(!_vm.setup.companies.minimize && _vm.companies == 0 && _vm.companiesDeactives == 0)?_c('v-card-actions',{staticClass:"d-block text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.$store.getters.isAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":_vm.setup.franchises.expand ? 12 : 6,"order":_vm.setup.franchises.expand ? 'first' : _vm.franchise != 0 || _vm.inactiveFranchises != 0 ? '' : 'last'}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","chart":""}},[_c('v-list-item',{staticClass:"primary elevation-4",attrs:{"header-chart":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"title mt-4 mb-0 text-uppercase white--text"},[_vm._v(" "+_vm._s(("" + (_vm.$tc('franchise.company',1))))+" ")]),_c('div',{staticClass:"overline mb-5 white--text ml-auto"},[_vm._v(" "+_vm._s(((_vm.$tc('franchise.total',2)) + " " + (this.franchise + this.inactiveFranchises)))+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.franchises.minimize = !_vm.setup.franchises.minimize}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-window-minimize")])],1)]}}],null,false,1707473798)},[_c('span',[_vm._v(_vm._s(_vm.$t('minimize')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.franchises.expand = !_vm.setup.franchises.expand}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-expand-arrows-alt")])],1)]}}],null,false,2285073226)},[_c('span',[_vm._v(_vm._s(_vm.$t('expand')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.getFranchise()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-redo")])],1)]}}],null,false,1109240205)},[_c('span',[_vm._v(_vm._s(_vm.$t('update')))])])],1),(!_vm.setup.franchises.minimize && _vm.franchise != 0 || _vm.inactiveFranchises != 0)?_c('v-card-actions',{attrs:{"content-chart":""}},[_c('fusioncharts',{ref:"fdc",attrs:{"type":_vm.chartAdminFranchise.type,"width":_vm.chartAdminFranchise.width,"height":_vm.chartAdminFranchise.height,"dataFormat":_vm.chartAdminFranchise.dataFormat,"dataSource":_vm.dataSourceFranchise}})],1):_vm._e(),(!_vm.setup.franchises.minimize && _vm.franchise == 0 && _vm.inactiveFranchises == 0)?_c('v-card-actions',{staticClass:"d-block text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.$store.getters.isAdmin || _vm.$store.getters.isFranchiseAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":_vm.setup.actors.expand ? 12 : 6,"order":_vm.setup.actors.expand ? 'first' : _vm.actorsOptions.active != 0 || _vm.actorsOptions.inactive != 0 ? '' : 'last'}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","chart":""}},[_c('v-list-item',{staticClass:"primary elevation-4",attrs:{"header-chart":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"title mt-4 mb-0 text-uppercase white--text"},[_vm._v(" "+_vm._s(("" + (this.$tc('actor.actor',2))))+" ")]),_c('div',{staticClass:"overline mb-5 white--text ml-auto"},[_vm._v(" "+_vm._s(((_vm.$tc('franchise.total',2)) + " " + _vm.actors))+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.actors.minimize = !_vm.setup.actors.minimize}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-window-minimize")])],1)]}}],null,false,2143145286)},[_c('span',[_vm._v(_vm._s(_vm.$t('minimize')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.actors.expand = !_vm.setup.actors.expand}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-expand-arrows-alt")])],1)]}}],null,false,3928597770)},[_c('span',[_vm._v(_vm._s(_vm.$t('expand')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.getActors()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-redo")])],1)]}}],null,false,105945498)},[_c('span',[_vm._v(_vm._s(_vm.$t('update')))])])],1),(!_vm.setup.actors.minimize && _vm.actorsOptions.active != 0 || _vm.actorsOptions.inactive != 0)?_c('v-card-actions',{attrs:{"content-chart":""}},[_c('fusioncharts',{attrs:{"type":_vm.chartAdminActors.type,"width":_vm.chartAdminActors.width,"height":_vm.chartAdminActors.height,"dataFormat":_vm.chartAdminActors.dataFormat,"dataSource":_vm.dataSourceActors}})],1):_vm._e(),(!_vm.setup.actors.minimize && _vm.actorsOptions.active == 0 && _vm.actorsOptions.inactive == 0)?_c('v-card-actions',{staticClass:"d-block text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.$store.getters.isAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":_vm.setup.servicesAndPackages.expand ? 12 : 6,"order":_vm.setup.servicesAndPackages.expand ? 'first' : _vm.services != 0 || _vm.packages != 0 ? '' : 'last'}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","chart":""}},[_c('v-list-item',{staticClass:"primary elevation-4",attrs:{"header-chart":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"title mt-4 mb-0 text-uppercase white--text"},[_vm._v(" "+_vm._s(((_vm.$tc('service.service',2)) + " " + (_vm.$t('and')) + " " + (_vm.$tc('package.package',1))))+" ")]),_c('div',{staticClass:"overline mb-5 white--text ml-auto"},[_vm._v(" "+_vm._s(((_vm.$tc('franchise.total',2)) + " " + (_vm.services + _vm.packages)))+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.servicesAndPackages.minimize = !_vm.setup.servicesAndPackages.minimize}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-window-minimize")])],1)]}}],null,false,2989290278)},[_c('span',[_vm._v(_vm._s(_vm.$t('minimize')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.servicesAndPackages.expand = !_vm.setup.servicesAndPackages.expand}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-expand-arrows-alt")])],1)]}}],null,false,343350890)},[_c('span',[_vm._v(_vm._s(_vm.$t('expand')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.getServiceAndPackages()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-redo")])],1)]}}],null,false,49185725)},[_c('span',[_vm._v(_vm._s(_vm.$t('update')))])])],1),(!_vm.setup.servicesAndPackages.minimize)?_c('v-card-actions',{attrs:{"content-chart":""}},[(_vm.services != 0 || _vm.packages != 0)?_c('fusioncharts',{attrs:{"type":_vm.chartAdminServicesAndPackagesX.type,"width":_vm.chartAdminServicesAndPackagesX.width,"height":_vm.chartAdminServicesAndPackagesX.height,"dataFormat":_vm.chartAdminServicesAndPackagesX.dataFormat,"dataSource":_vm.dataSourceServicesAndPackages}}):_vm._e()],1):_vm._e(),(!_vm.setup.servicesAndPackages.minimize && _vm.services == 0 && _vm.packages == 0)?_c('v-card-actions',{staticClass:"d-block text-center my-5 py-5"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])]):_vm._e()],1)],1):_vm._e(),(!_vm.$store.getters.isRoot && !_vm.$store.getters.isAdmin && !this.$store.getters.isFranchiseAdmin && this.permission.subscriber)?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":_vm.setup.subscribers.expand ? 12 : 6,"order":_vm.setup.subscribers.expand ? 'first' : _vm.subscribersOptions.prospect != 0 || _vm.subscribersOptions.active != 0 || _vm.subscribersOptions.inactive != 0 ? '' : 'last'}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","chart":""}},[_c('v-list-item',{staticClass:"primary elevation-4",attrs:{"header-chart":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"title mt-4 mb-0 text-uppercase white--text"},[_vm._v(" "+_vm._s(("" + (_vm.$tc('subscribers.subscribers',2))))+" ")]),_c('div',{staticClass:"overline mb-5 white--text ml-auto"},[_vm._v(" "+_vm._s(((_vm.$tc('franchise.total',2)) + " " + (this.subscribers)))+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.subscribers.minimize = !_vm.setup.subscribers.minimize}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-window-minimize")])],1)]}}],null,false,3667467494)},[_c('span',[_vm._v(_vm._s(_vm.$t('minimize')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.subscribers.expand = !_vm.setup.subscribers.expand}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-expand-arrows-alt")])],1)]}}],null,false,727146730)},[_c('span',[_vm._v(_vm._s(_vm.$t('expand')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.getSubscribers()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-redo")])],1)]}}],null,false,3591015979)},[_c('span',[_vm._v(_vm._s(_vm.$t('update')))])])],1),(!_vm.setup.subscribers.minimize)?_c('v-card-actions',{attrs:{"content-chart":""}},[(_vm.subscribersOptions.prospect != 0 || _vm.subscribersOptions.active != 0 || _vm.subscribersOptions.inactive != 0)?_c('fusioncharts',{attrs:{"type":_vm.chartUserSubscribers.type,"width":_vm.chartUserSubscribers.width,"height":_vm.chartUserSubscribers.height,"dataFormat":_vm.chartUserSubscribers.dataFormat,"dataSource":_vm.dataSourceSubscribers}}):_vm._e()],1):_vm._e(),(!_vm.setup.subscribers.minimize && _vm.subscribersOptions.prospect == 0 && _vm.subscribersOptions.active == 0 && _vm.subscribersOptions.inactive == 0)?_c('v-card-actions',{staticClass:"d-block text-center my-5 py-5"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])]):_vm._e()],1)],1):_vm._e(),(!_vm.$store.getters.isRoot && !_vm.$store.getters.isAdmin && !this.$store.getters.isFranchiseAdmin)?_c('v-col',{attrs:{"cols":"12","xs":"12","order":_vm.setup.requests.expand ? 'first' : '',"sm":_vm.setup.requests.expand ? 12 : 6}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","chart":""}},[_c('v-list-item',{staticClass:"primary elevation-4",attrs:{"header-chart":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"title mt-4 mb-0 text-uppercase white--text"},[_vm._v(" "+_vm._s(("" + (_vm.$tc('requests.requests',2))))+" ")]),_c('div',{staticClass:"overline mb-5 white--text ml-auto"},[_vm._v(" "+_vm._s(((_vm.$tc('franchise.total',2)) + " " + (this.requests.total)))+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.requests.minimize = !_vm.setup.requests.minimize}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-window-minimize")])],1)]}}],null,false,1285530566)},[_c('span',[_vm._v(_vm._s(_vm.$t('minimize')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.setup.requests.expand = !_vm.setup.requests.expand}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-expand-arrows-alt")])],1)]}}],null,false,3225165962)},[_c('span',[_vm._v(_vm._s(_vm.$t('expand')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.getRequests()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-redo")])],1)]}}],null,false,2457362976)},[_c('span',[_vm._v(_vm._s(_vm.$t('update')))])])],1),(!_vm.setup.requests.minimize)?_c('v-card-actions',{attrs:{"content-chart":""}},[( _vm.requests.service_request != 0 || _vm.requests.feasible_service != 0 || _vm.requests.infeasible_service != 0 || _vm.requests.awaiting_acceptance != 0 || _vm.requests.generate_charge_note != 0 || _vm.requests.payment_pending != 0 || _vm.requests.awaiting_installation != 0 || _vm.requests.installed != 0 || _vm.requests.inactive != 0)?_c('fusioncharts',{attrs:{"type":_vm.chartUserRequests.type,"width":_vm.chartUserRequests.width,"height":_vm.chartUserRequests.height,"dataFormat":_vm.chartUserRequests.dataFormat,"dataSource":_vm.dataSourceRequest}}):_vm._e()],1):_vm._e(),(!_vm.setup.requests.minimize && _vm.requests.draft == 0 && _vm.requests.service_request == 0 && _vm.requests.feasible_service == 0 && _vm.requests.infeasible_service == 0 && _vm.requests.awaiting_acceptance == 0 && _vm.requests.generate_charge_note == 0 && _vm.requests.payment_pending == 0 && _vm.requests.awaiting_installation == 0 && _vm.requests.installed == 0 && _vm.requests.inactive == 0)?_c('v-card-actions',{staticClass:"d-block text-center my-5 py-5"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])]):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }