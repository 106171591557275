export default {
    data: () => {
        return{
            // GENERAL
            title: process.env.VUE_APP_CLIENT,
            perPage: 10,
            page: 1,
            totalValues: 0,
            getAll: 'All',
            loadingCompanies: false,
            loadingFranchises: false,
            loadingActors: false,
            loadingSubscribers: false,
            loadingServicesPacks: false,
            permission:{subscriber:false},
            // END GENERAL
            // COMPANY START
            companies: 0,
            companiesDeactives: 0,
            // END COMPANY
            // FRANCHISE START
            franchise: 0,
            // franchise: [],
            inactiveFranchises: [],
            // END COMPANY
            // ACTORS START
            actors: 0,
            actorsOptions: {
                active: 0,
                inactive: 0
            },
            // END ACTORS
            // SERVICES AND PACKAGES START
            services: [],
            packages: [],
            // END SERVICES AND PACKAGES
            // SUBSCRIBER START
            subscribers: 0,
            subscribersOptions: {
                prospect: 0,
                active: 0,
                inactive: 0
            },
            // END SUBSCRIBER
            // REQUESTS START
            requests : {
                /*draft : 0,
                service_request : 0,
                feasible_service : 0,
                infeasible_service : 0,
                awaiting_acceptance : 0,
                generate_charge_note : 0,
                payment_pending : 0,
                awaiting_installation : 0,
                installed : 0,
                active : 0,*/
                request: 0,
                completed: 0,
                inactive: 0,
                total: 0
            },
            // END REQUESTS
            //SETUP START
            setup: {
                companies : {
                    minimize: false,
                    expand: false,
                },
                franchises : {
                    minimize: false,
                    expand: false,
                },
                servicesAndPackages : {
                    minimize: false,
                    expand: false,
                },
                actors : {
                    minimize: false,
                    expand: false,
                },
                subscribers : {
                    minimize: false,
                    expand: false,
                },
                requests : {
                    minimize: false,
                    expand: false,
                },
            },
            //END SETUP
            //BASE CHARTS START
            chartRootCompany: {
                type: 'pie2d',
                width: '100%',
                // height: '50%',
                dataFormat: 'json',
                radioValue: 'none',
                lastActive: 'none',
                dataSource: {
                    "chart": {
                        "caption": "this.$tc('company.company',1) + ' ' + this.$tc('company.active') + ' ' + this.$tc('or') + ' ' + this.$tc('company.inactive')",
                        "plottooltext": "<b>$percentValue</b> empresas son inactivas",
                        "showLegend": "1",
                        "showPercentValues": "1",
                        "legendPosition": "bottom",
                        "useDataPlotColorForLabels": "1",
                        "enableMultiSlicing": "0",
                        "theme": "fusion",
                        "showlegend": "0"
                    },
                    "data": [
                        {
                        "label": "this.$t('company.active')",
                        "value": 1
                        // "value": this.companies.length
                        },
                        {
                        "label": "this.$t('company.inactive')",
                        // "label": this.$t('company.inactive'),
                        "value": 1
                        // "value": this.companiesDeactives.length
                        },
                    ]
                }
            },
            chartAdminFranchise: {
                type: 'pie2d',
                width: '100%',
                // height: '50%',
                dataFormat: 'json',
                radioValue: 'none',
                lastActive: 'none',
                dataSource: {
                "chart": {
                    "caption": "Franquicias Totales: " + "this.franchise.length",
                    "plottooltext": "<b>$percentValue</b> Franquicias",
                    "showLegend": "1",
                    "showPercentValues": "1",
                    "legendPosition": "bottom",
                    "useDataPlotColorForLabels": "1",
                    "enableMultiSlicing": "0",
                    "theme": "fusion",
                    "showlegend": "0"
                },
                "data": [
                    {
                    "label": "this.$t('company.active')",
                    // "label": this.$t('company.active'),
                    "value": 1
                    // "value": this.franchise.length
                    },
                    {
                    "label": "this.$t('company.inactive')",
                    // "label": this.$t('company.inactive'),
                    "value": 1
                    },
                ]
                }
            },
            chartAdminServicesAndPackagesX: {
                width: '100%',
                // height: '50%',
                type: 'column2d',
                dataFormat: 'json',
                dataSource: {
                "chart": {
                    "caption": "Servicios y Paquetes Registrados",
                    // "subCaption": "In MMbbl = One Million barrels",
                    "xAxisName": "Paquetes y Servicios",
                    "yAxisName": "Cantidad",
                    // "numberSuffix": "K",
                    "theme": "fusion"
                },
                "data": [
                    {
                    "label": "this.$tc('service.service',2)",
                    // "label": this.$tc('service.service',2),
                    "value": 2
                    // "value": this.services.length
                    },
                    {
                    "label": "this.$tc('package.package',1)",
                    "value": 3
                    // "value": this.packages.length
                    },
                ]
                }
            },
            chartAdminActors: {
                type: 'doughnut3D',
                width: '100%',
                // height: '50%',
                dataFormat: 'json',
                radioValue: 'none',
                lastActive: 'none',
                dataSource: {
                "chart": {
                    "caption": "Actores Totales: " + "this.actors.length",
                    "plottooltext": "<b>$percentValue</b> Franquicias",
                    "showLegend": "1",
                    "showPercentValues": "1",
                    "legendPosition": "bottom",
                    "useDataPlotColorForLabels": "1",
                    "enableMultiSlicing": "0",
                    "theme": "fusion",
                    "showlegend": "0"
                },
                "data": [
                    {
                    "label": "this.$t('company.active')",
                    // "label": this.$t('company.active'),
                    "value": 1
                    // "value": this.actors.length
                    },
                    {
                    "label": "this.$t('company.inactive')",
                    // "label": this.$t('company.inactive'),
                    "value": 4
                    },
                ]
                }
            },
            chartUserSubscribers: {
                type: 'doughnut3D',
                width: '100%',
                // height: '50%',
                dataFormat: 'json',
                radioValue: 'none',
                lastActive: 'none',
                dataSource: {
                "chart": {
                    "caption": "Abonados Totales: " + "this.subscribers.length",
                    "plottooltext": "<b>$percentValue</b> Abonados",
                    "showLegend": "1",
                    "showPercentValues": "1",
                    "legendPosition": "bottom",
                    "useDataPlotColorForLabels": "1",
                    "enableMultiSlicing": "0",
                    "theme": "fusion",
                    "showlegend": "0"
                },
                "data": [
                    {
                    "label": "this.$tc('subscribers.prospect',2)",
                    "value": 10
                    // "value": this.subscribers.length
                    },
                    {
                    "label": "this.$t('menu.subscribers')",
                    // "label": this.$t('menu.subscribers'),
                    "value": 1
                    // "value": this.subscribers.length
                    },
                    {
                    "label": "this.$t('menu.subscribers')",
                    // "label": this.$t('menu.subscribers'),
                    "value": 4
                    },

                ]
                }
            },
            chartUserRequests: {
                width: '100%',
                // height: '50%',
                type: 'column2d',
                dataFormat: 'json',
                dataSource: {
                "chart": {
                    "caption": "Solicitudes Registradas",
                    // "subCaption": "In MMbbl = One Million barrels",
                    "xAxisName": "Paquetes y Servicios",
                    "yAxisName": "Cantidad",
                    // "numberSuffix": "K",
                    "theme": "fusion",
                },
                "data": [
                    {
                    "label": "this.$tc('service.service',2)",
                    // "label": this.$tc('service.service',2),
                    "value": 2
                    // "value": this.services.length
                    },
                    {
                    "label": "this.$tc('package.package',1)",
                    "value": 3
                    // "value": this.packages.length
                    },
                ]
                }
            },
            //BASE CHART END
        }
    },
    computed: {
        dataSourceCompany() {
            return {
                "chart": {
                    "caption": this.$tc('company.company',1) + ' ' + this.$tc('franchise.active',2) + ' ' + this.$tc('or') + ' ' + this.$tc('franchise.inactive',2),
                    "plottooltext": `<b>$percentValue</b>`+ ' ' + this.$tc('company.company',1) + ' ' + this.$tc('franchise.active',2),
                    "showLegend": "1",
                    "showPercentValues": "1",
                    "legendPosition": "bottom",
                    "useDataPlotColorForLabels": "1",
                    "enableMultiSlicing": "0",
                    "theme": "fusion",
                    "showlegend": "0",
                    "bgColor": this.$vuetify.theme.dark === true ?
                        this.$vuetify.theme.themes.dark.chart.bgColor :
                        this.$vuetify.theme.themes.light.chart.bgColor,
                    // "paletteColors": this.$vuetify.theme.dark === false ?
                    //     this.$store.getters.primaryColor === null ?
                    //         this.$vuetify.theme.themes.light.primary :
                    //         this.$store.getters.primaryColor
                    //             +`,`+this.$vuetify.theme.themes.light.chart.paletteColors :
                    //     this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.dark.chart.paletteColors
                },
                "data": [
                    {
                    "label": this.$tc('franchise.active',2),
                    "value": this.companies,
                    "color": this.$vuetify.theme.themes.light.primary
                    },
                    {
                    "label": this.$tc('franchise.inactive',2),
                    "value": this.companiesDeactives,
                    "color": this.$vuetify.theme.dark === false ?
                        this.$vuetify.theme.themes.light.font_color :
                        this.$vuetify.theme.themes.dark.chart.paletteColors.split(',')[1]
                    },
                ]
            }
        },
        dataSourceFranchise(){
            return {
                "chart": {
                    // "caption": this.$tc('franchise.company',1) + ' ' + this.$tc('franchise.total',2) + ': ' + this.franchise.length,
                    "plottooltext": "<b>$percentValue</b>" + ' ' + this.$tc('franchise.company',1) + this.$t('company.active'),
                    "showLegend": "1",
                    // "exportEnabled": "1",
                    "showPercentValues": "1",
                    "legendPosition": "bottom",
                    "useDataPlotColorForLabels": "1",
                    "enableMultiSlicing": "0",
                    "theme": "fusion",
                    "showlegend": "0",
                    "bgColor": this.$vuetify.theme.dark === true ? this.$vuetify.theme.themes.dark.chart.bgColor : this.$vuetify.theme.themes.light.chart.bgColor,
                    "paletteColors": this.$vuetify.theme.dark === false ? this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.light.chart.paletteColors : this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.dark.chart.paletteColors
                },
                "data": [
                    {
                        "label": this.$t('company.active'),
                        "value": this.franchise
                    },
                    {
                        "label": this.$t('company.inactive'),
                        "value": this.inactiveFranchises
                    },
                ]
            }
        },
        dataSourceServicesAndPackages(){
            return{
                "chart": {
                    // "caption": this.$tc('service.service',2) + ' ' + this.$t('and') + ' ' + this.$tc('package.package',1),
                    // "subCaption": "In MMbbl = One Million barrels",
                    // "xAxisName": "Paquetes y Servicios",
                    "yAxisName": this.$t('quantity'),
                    // "numberSuffix": "K",
                    "theme": "fusion",
                    "bgColor": this.$vuetify.theme.dark === true ? this.$vuetify.theme.themes.dark.chart.bgColor : this.$vuetify.theme.themes.light.chart.bgColor,
                    "paletteColors": this.$vuetify.theme.dark === false ? this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.light.chart.paletteColors : this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.dark.chart.paletteColors
                },
                "data": [
                    {
                    "label": this.$tc('service.service',2),
                    "value": this.services
                    },
                    {
                    "label": this.$tc('package.package',1),
                    "value": this.packages
                    },
                ]
            }
        },
        dataSourceActors(){
            return{
                "chart": {
                    // "caption": this.$tc('actor.actor',2) + ' ' + this.$tc('franchise.total',2) + ': ' + '12',
                    // "caption": this.$tc('actor.actor',2) + ' ' + this.$tc('franchise.total',2) + ': ' + this.actors.length,
                    // "plottooltext": "<b>$percentValue</b> Franquicias",
                    "showPercentValues": "1",
                    "legendPosition": "bottom",
                    "useDataPlotColorForLabels": "1",
                    "enableMultiSlicing": "0",
                    "theme": "fusion",
                    "showLegend": "1",
                    "bgColor": this.$vuetify.theme.dark === true ? this.$vuetify.theme.themes.dark.chart.bgColor : this.$vuetify.theme.themes.light.chart.bgColor,
                    "paletteColors": this.$vuetify.theme.dark === false ? this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.light.chart.paletteColors : this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.dark.chart.paletteColors
                },
                "data": [
                    {
                    "label": this.$tc('active', 1),
                    "value": this.actorsOptions.active
                    },
                    {
                    "label": this.$tc('inactive', 1),
                    "value": this.actorsOptions.inactive
                    },
                ]
            }
        },
        dataSourceSubscribers(){
            return{
                "chart": {
                    // "caption": this.$t('DSB.menu.subscribers') + ' ' + this.$tc('franchise.total',2) + ': ' + '25',
                    // "caption": this.$t('menu.subscribers') + ' ' + this.$tc('franchise.total',2) + ': ' + this.subscribers.length,
                    // "plottooltext": "<b>$percentValue</b> Franquicias",
                    "showPercentValues": "1",
                    "legendPosition": "bottom",
                    "useDataPlotColorForLabels": "1",
                    "enableMultiSlicing": "0",
                    "theme": "fusion",
                    "showLegend": "1",
                    "bgColor": this.$vuetify.theme.dark === true ? this.$vuetify.theme.themes.dark.chart.bgColor : this.$vuetify.theme.themes.light.chart.bgColor,
                    "paletteColors": this.$vuetify.theme.dark === false ? this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.light.chart.paletteColors : this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.dark.chart.paletteColors

                },
                "data": [
                    {
                    "label": this.$tc('subscribers.prospect',2),
                    "value": this.subscribersOptions.prospect
                    },
                    {
                    "label": this.$tc('active',1),
                    "value": this.subscribersOptions.active
                    },
                    {
                    "label": this.$tc('inactive',1),
                    "value": this.subscribersOptions.inactive
                    },
                ]
            }
        },
        dataSourceRequest(){
            return{
                "chart": {
                    // "caption": this.$tc('requests.requests',2),
                    // "subCaption": "In MMbbl = One Million barrels",
                    // "xAxisName": "Paquetes y Servicios",
                    "yAxisName": this.$t('quantity'),
                    // "numberSuffix": "K",
                    "theme": "fusion",
                    "bgColor": this.$vuetify.theme.dark === true ? this.$vuetify.theme.themes.dark.chart.bgColor : this.$vuetify.theme.themes.light.chart.bgColor,
                    "paletteColors": this.$vuetify.theme.dark === false ? this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.light.chart.paletteColors : this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.dark.chart.paletteColors,
                },
                "data": [
                    /*{
                    "label": this.$t('service.draft'),
                    "value": this.requests.draft
                    },
                    {
                    "label": this.$t('service.service_request'),
                    "value": this.requests.service_request
                    },
                    {
                    "label": this.$t('service.feasible_service'),
                    "value": this.requests.feasible_service
                    },
                    {
                    "label": this.$t('service.infeasible_service'),
                    "value": this.requests.infeasible_service
                    },
                    {
                    "label": this.$t('service.awaiting_acceptance'),
                    "value": this.requests.awaiting_acceptance
                    },
                    {
                    "label": this.$t('service.generate_charge_note'),
                    "value": this.requests.generate_charge_note
                    },
                    {
                    "label": this.$t('service.payment_pending'),
                    "value": this.requests.payment_pending
                    },
                    {
                    "label": this.$t('service.awaiting_installation'),
                    "value": this.requests.awaiting_installation
                    },
                    {
                    "label": this.$t('service.installed'),
                    "value": this.requests.installed
                    },
                    {
                    "label": this.$t('service.active'),
                    "value": this.requests.active
                    },*/
                    {
                        "label": this.$tc('service.in_progress', 2),
                        "value": this.requests.request
                    },
                    {
                        "label": this.$tc('requests.completed', 2),
                        "value": this.requests.completed
                    },
                    {
                        "label": this.$tc('requests.discarded',2),
                        "value": this.requests.inactive
                    },
                ]
            }
        },
    },
    methods:{
        getCompanies() {
            if (this.$store.getters.isRoot) {
                // this.allExchangeRates()
                this.loadingCompanies = true
                axios.get(this.$store.getters.getCompanies(), {
                    params: {
                        page: this.page,
                        paginate: this.perPage
                    }
                })
                .then(response => {
                    this.companies = response.data.companies.total
                    this.companiesDeactives = response.data.deactiveCompany.total
                })
                .finally(()=>{
                    this.loadingCompanies = false
                })
            }
        },
        getFranchise() {
            if (this.$store.getters.isAdmin) {
                this.loadingFranchises = true
                axios.get(this.$store.getters.getFranchises(), {
                    params: {
                        page: this.page,
                        paginate: this.perPage
                    }
                })
                .then(response => {
                    this.franchise = response.data.franchises.total
                    this.inactiveFranchises = response.data.inactiveFranchises.total
                    // eslint-disable-next-line no-console
                    // console.log('Asi retorna cuando no tiene nada: ', this.inactiveFranchises);
                })
                .finally(()=>{
                    this.loadingFranchises = false
                })
            }
        },
        getServiceAndPackages(){
            if (this.$store.getters.isAdmin) {
                this.loadingServicesPacks = true
                axios.get(this.$store.getters.getPackage(),{
                    params:{
                    page:this.page,
                    paginate:this.perPage
                    }
                })
                .then(response => {
                    this.packages = response.data.package.total
                    // eslint-disable-next-line no-console
                    // console.log('Esto es lo que retorna packages', response ,this.packages, 'Estas es la cantidad de packages', this.packages.length)
                })
                .finally(()=>{
                    this.loadingServicesPacks = false
                })
                this.loadingServicesPacks = true
                axios.get(this.$store.getters.getService(),{
                params:{
                    page:this.page,
                    paginate:this.perPage
                }
                })
                .then(response => {
                    this.services = response.data.service.total
                })
                .finally(()=>{
                    this.loadingServicesPacks = false
                })
            }
        },
        getActors () {
            if (this.$store.getters.isAdmin || this.$store.getters.isFranchiseAdmin) {
                this.loadingActors = true
                let ops = {
                    params: {}
                }
                if (this.$store.getters.isFranchiseAdmin) {
                    ops.params.franchise_id = this.$store.getters.getFranchiseID
                }
                axios.get(this.$store.getters.getActorsCount, ops)
                    .then(response => {
                        this.actors = response.data.inActive + response.data.Active
                        this.actorsOptions.active = response.data.Active
                        this.actorsOptions.inactive = response.data.inActive
                    })
                    .finally(()=>{
                        this.loadingActors = false
                    })
            }
        },
        getSubscribers(){
            if (!this.$store.getters.isRoot && !this.$store.getters.isAdmin && !this.$store.getters.isFranchiseAdmin && this.permission.subscriber) {
                this.loadingSubscribers = true
                // Prospects
                axios.get(this.$store.getters.getSubscribers(), {
                    params: {
                        'prospects_only': 'YES'
                    }
                })
                .then(response => {
                    this.subscribersOptions.prospect = response.data.success && response.data.success.total ? response.data.success.total : 0
                    this.subscribers += this.subscribersOptions.prospect
                })
                // Active
                axios.get(this.$store.getters.getSubscribers())
                .then(response => {
                    this.subscribersOptions.active = response.data.success && response.data.success.total ? response.data.success.total : 0
                    this.subscribers += this.subscribersOptions.active
                })
                // Inctive
                axios.get(this.$store.getters.getSubscribers(), {
                    params: {
                        'inactive_only': 'YES'
                    }
                })
                .then(response => {
                    this.subscribersOptions.inactive = response.data.success && response.data.success.total ? response.data.success.total : 0
                    this.subscribers += this.subscribersOptions.inactive
                })
                .finally(()=>{
                    this.loadingSubscribers = false
                })
            }
        },
        getRequests(){
            if (!this.$store.getters.isAdmin && !this.$store.getters.isRoot && !this.$store.getters.isFranchiseAdmin) {
                this.loading = true
                axios.get(this.$store.getters.getDataRequests)
                .then(response => {
                    // eslint-disable-next-line no-console
                    // console.log('Esto es lo que retorna Requests', response)
                    this.requests = {
                        /*draft : response.data.draft,
                        service_request : response.data.service_request,
                        feasible_service : response.data.feasible_service,
                        infeasible_service : response.data.infeasible_service,
                        awaiting_acceptance : response.data.awaiting_acceptance,
                        generate_charge_note : response.data.generate_charge_note,
                        payment_pending : response.data.payment_pending,
                        awaiting_installation : response.data.awaiting_installation,
                        installed : response.data.installed,
                        active : response.data.active,*/
                        request : response.data.request,
                        completed : response.data.completed,
                        inactive : response.data.inactive,
                        total: response.data.total
                    }
                })
                .finally(()=>{
                    this.loading = false
                })
            }
        },
        printChartFranchisePDF(){
            axios.get(this.$store.getters.getChartFranchisePDF,{
                params: {
                }
            })
              .then(response =>{
                this.downloadFile('window',response.data)
              })
        },
        //ACOMODAR!!!
        printEdgar(){
            axios.get(this.$store.getters.getInstalationOrderPDF(3))
              .then(response =>{
                this.downloadFile('window',response.data)
              })
        },
        SearchPolicies(){
            this.permission.subscriber = this.getPermissionGroup({
              permission:[
                'index_subscriber',
              ],
              mode:'all'
            });
          },
        /*allExchangeRates(){
            axios.get(this.$store.getters.exchangeRates(),{
            params: {
                'paginate': 100,
            }
            })
            .then(response => {
                if (response.data.success) {
                let arr = []
                let current = {}
                // current['name'] = this.$t('system.base_currency')
                current['name'] = Object.keys(this.$store.getters.getBaseCurrency)[0]
                current['value'] = this.$store.getters.getBaseCurrency
                arr.push(current)
                Object.values(response.data.success.data).forEach(function (obj) {
                    let currency = {}
                    let content = {}
                    let item = {}
                    content['rate'] = obj.rate
                    content['icon'] = obj.icon
                    content['symbol'] = obj.symbol
                    currency[obj.name] = content
                    item['name'] = obj.name
                    item['value'] = currency
                    arr.push(item)
                })
                this.items = arr
                }
            })
            .catch(err=>{
                // console.log(err)
            })
        },
        changeCurrency(){
            this.$store.dispatch('changeCurrency', this.rates)
        }*/
    },
    mounted(){
        /*if (!this.$store.getters.isRoot) {
            this.allExchangeRates()
        }*/
        this.SearchPolicies();
        this.getCompanies()
        this.getFranchise()
        this.getActors()
        this.getServiceAndPackages()
        this.getRequests()
        setTimeout(() => {// Delayed to avoid login notifications overlapping
            this.getSubscribers()
        }, 700)
    },
    created() {
    },
}
